import styled from 'styled-components'

interface WrapperProps {
	noBackground: boolean
}

export const Wrapper = styled.div<WrapperProps>`
	height: 75px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${({ noBackground }) => !noBackground && 'rgba(10, 10, 10, 0.95)'};
`

export const NavContent = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 10px;
	width: 100%;
`

export const NavWrapper = styled.div`
	display: flex;
	align-items: center;
	padding-right: 15px;

	& > a:not(:last-child) {
		display: none;
	}

	#theme-button {
		display: block !important;
	}

	@media (min-width: 1570px) {
		/* All the links */
		& > a {
			display: flex !important;
		}

		/* Hamburger button */
		#hamburger-button {
			display: none;
		}

		& > :not(:first-child) {
			margin-left: 30px;
		}

		#theme-button {
			margin-right: 0;
		}
	}
`

interface MobileNavWrapperProps {
	open: boolean
}

export const MobileNavWrapper = styled.div<MobileNavWrapperProps>`
	position: absolute;
	top: 75px;
	/* Referencing the mobile padding */
	width: 100%;
	max-height: 572px;

	transition: height 0.27s;

	height: ${({ open }) => (open ? 572 : 0)}px;

	overflow: hidden;

	display: flex;
	flex-direction: column;
	z-index: 100;

	/* The Navlinks */
	& > * {
		background: rgba(18, 18, 18, 0.97);
		min-height: 38px;
		height: 38px;

		:not(:last-child) {
			border-bottom: 1px solid hsla(0, 0%, 100%, 0.065);
		}
	}

	@media (min-width: 1570px) {
		display: none;
	}
`

export const UserWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	position: relative;
	background-color: #1b1c21;
	border-radius: 40px;
	padding: 4px 12px 4px 6px;

	& > :first-child {
		margin-right: 10px;
	}
`

export const UserDropdownWrapper = styled.div`
	position: absolute;
	top: 80px;
	right: 25px;

	flex-direction: column;

	background-color: ${({ theme }) => theme.colors.body};
	border-radius: 6px;
	box-shadow: ${({ theme }) => theme.current === 'dark' ? '0px 5px 15px 5px rgba(0,0,0,0.5)' : '0px 5px 15px 5px rgba(0,0,0,0.25)'};
	padding: 5px 10px;
	width: 250px;
	height: auto;

	text-align: start;
	text-transform: none;
	text-overflow: ellipsis;

	z-index: 99;

	/* The Navlinks */
	& > a {
		color: ${({ theme }) => theme.current === 'dark' ? theme.colors.textTertiary : theme.colors.textSecondary};
		border-bottom: 1px solid ${({ theme }) => theme.colors.accentSecondary};
		min-height: 38px;
		height: 38px;

		:hover {
			color: ${({ theme }) => theme.colors.textPrimary};
		}
	}

	> :last-child {
		border-bottom: none;
	}

	@media (min-width: 1570px) {
		display: flex;
	}
`

export const UserInfo = styled.div`
	display: flex;
	align-items: center;
	font-family: ${({ theme }) => theme.fonts.heading};
	text-transform: uppercase;
	padding: 8px 5px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.accentSecondary};
`

export const NewTag = styled.span`
	background-color: ${({ theme }) => theme.colors.primaryGreen};
	border-radius: 5px;
	color: white;
	font-weight: 700;
	margin-left: auto;
	text-transform: uppercase;
	padding: 2px 5px;
	white-space: nowrap;
`
