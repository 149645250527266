import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { maxWidth, MaxWidthProps, padding, PaddingProps } from 'styled-system'

interface WrapperProps {
	background: string
	disableNavigationPadding: boolean
}

const BackgroundStyles = css`
	&::before {
		content: ' ';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: rgba(3, 3, 3, 0.9);
	}
`

export const Wrapper = styled.div<WrapperProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: ${({ disableNavigationPadding }) => (disableNavigationPadding ? 0 : 75)}px;
	min-height: 100vh;
	background: ${({ background }) => background && `url(${background})`};
	background-position: 50% 0px;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center center;
	background-size: cover;

	${({ background }) => background && BackgroundStyles};
`

interface NavigationProps {
	relative?: boolean
}

export const Navigation = styled.nav<NavigationProps>`
	position: ${({ relative }) => relative ? 'relative' : 'fixed'};
	top: 0;
	width: 100%;
	z-index: 999;
`

interface ContentWrapperProps extends MaxWidthProps, PaddingProps {
	disableMaxWidth: boolean;
	stylesOverride?: FlattenSimpleInterpolation;
	isBackground?: boolean;
}

export const Content = styled.section<ContentWrapperProps>`
	width: 100%;

	display: flex;
	flex-direction: column;
	position: relative;

	${({ isBackground }) => isBackground && css`
		z-index: 1;
	`}

	${({ stylesOverride }) => stylesOverride};
	${({ disableMaxWidth }) => (disableMaxWidth ? '' : maxWidth)};
	${padding};
`

export const Footer = styled.div`
	width: 100%;
	margin-top: auto;
`

export const SideBanner = styled.img`
	border: 1px solid ${({ theme }) => theme.colors.accentPrimary};
	width: 200px;
	align-self: center;
	margin: 20px 10px;
	
	@media (max-width: 1800px) {
		width: 200px;
		height: auto;
	}

	@media (max-width: 1610px) {
		width: 150px;
	}

	@media (max-width: 1510px) {
		width: 125px;
	}

	@media (max-width: 1440px) {
		display: none;
	}
`;

export const ContentWrapper = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`;
