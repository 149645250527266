import { Banner } from '@utils';
import React, { FC, Fragment, ReactNode } from 'react'
import { FlattenSimpleInterpolation } from 'styled-components'
import { SpaceProps } from 'styled-system'
import { Wrapper, Navigation, Content, Footer, SideBanner, ContentWrapper } from './styles'

interface PageTemplateProps {
	navigation: ReactNode;
	header?: ReactNode;
	disableNavigationPadding?: boolean;
	footer?: ReactNode;
	contentStylesOverride?: FlattenSimpleInterpolation;
	contentPadding?: SpaceProps['padding'];
	background?: string;
	disableMaxWidth?: boolean;
	navRelative?: boolean;
	banners?: Banner[];
}

const PageTemplate: FC<PageTemplateProps> = ({
	navigation,
	header,
	disableNavigationPadding,
	contentStylesOverride,
	contentPadding = '0px 15px',
	disableMaxWidth,
	footer,
	children,
	background,
	navRelative,
	banners,
}) => {
	const leftBanner = banners?.find(b => b.id === 'left');
	const rightBanner = banners?.find(b => b.id === 'right');

	return (
		<Fragment>
			<Wrapper disableNavigationPadding={disableNavigationPadding} background={background}>
				<Navigation relative={navRelative}>{navigation}</Navigation>
				{header}
				<ContentWrapper>
					{leftBanner && (
						<a href={leftBanner.link} target={'_blank'} rel={'noreferer'} style={{ height: 'fit-content' }}>
							<div style={{ display: 'flex' }}>
								<SideBanner src={leftBanner.image}/>
							</div>
						</a>
					)}
					<Content
						stylesOverride={contentStylesOverride}
						disableMaxWidth={disableMaxWidth}
						maxWidth={['90%', '90%', 750, 970, 1170]}
						padding={contentPadding}
					>
						{children}
					</Content>
					{rightBanner && (
						<a href={rightBanner.link} target={'_blank'} rel={'noreferer'} style={{ height: 'fit-content' }}>
							<div style={{ display: 'flex' }}>
								<SideBanner src={rightBanner.image}/>
							</div>
						</a>
					)}
				</ContentWrapper>
				{footer && <Footer>{footer}</Footer>}
			</Wrapper>
		</Fragment>
	)
}

export default PageTemplate
