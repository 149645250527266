import { FC, useEffect, useState } from 'react';
import { Button, Icon, Img, Link, NavLink, P, Tooltip } from '@components/common';
import { MobileNavWrapper, NavContent, NavWrapper, NewTag, UserDropdownWrapper, UserInfo, UserWrapper, Wrapper } from './styles';
import { useAuthContext } from '@context';
import { ApiService } from '@services';
import { useGetUserProfile, useLocalStorage } from '@hooks';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import toast from 'react-hot-toast';
import { AnimatePresence, motion } from 'framer-motion';

interface NavigationBarProps {
	noBackground?: boolean;
}

const NavigationBar: FC<NavigationBarProps> = ({ noBackground }) => {
	const { user, logout } = useAuthContext();
	const router = useRouter();

	const [theme, setTheme] = useLocalStorage<'light' | 'dark'>('theme', 'light');

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [mobileNavOpen, setMobileNavOpen] = useState(false);

	const handleUserClick = () => {
		setDropdownOpen(!dropdownOpen);
		if (!dropdownOpen === true) setMobileNavOpen(false);
	}

	const handleMobileClick = () => {
		setMobileNavOpen(!mobileNavOpen);
		if (!mobileNavOpen === true) setDropdownOpen(false);
	}

	const themeButtonAnimations = {
		initial: { y: 10, opacity: 0 },
		animate: { y: 0, opacity: 1 },
		exit: { y: 10, opacity: 0 },
		transition: { duration: 0.2 },
	}

	return (
		<Wrapper noBackground={noBackground}>
			<NavContent>
				<NextLink href={'/'} passHref>
					<Link lineHeight={1}>
						<Img src={'/img/logo.png'} maxWidth={118} />
					</Link>
				</NextLink>
				<NavWrapper>
					<NextLink href={'/'} passHref>
						<NavLink>Home</NavLink>
					</NextLink>
					<NextLink href={'/leaderboards'} passHref>
						<NavLink>Leaderboards</NavLink>
					</NextLink>
					<NextLink href={'/tickets'} passHref>
						<NavLink>Support</NavLink>
					</NextLink>
					<NavLink href={'/link'}>Link Your Accounts</NavLink>
					<NavLink href={'https://donate.rustoria.co'}>Donate</NavLink>
					<NextLink href={'/servers'} passHref>
						<NavLink>Servers</NavLink>
					</NextLink>
					<NextLink href={'/rules'} passHref>
						<NavLink>Rules</NavLink>
					</NextLink>
					<NextLink href={'/clans'} passHref>
						<NavLink>Clans</NavLink>
					</NextLink>
					<NavLink href={'https://discord.com/invite/BbfhEhT'}>Discord</NavLink>
					<NavLink href={'https://rustoria.co/signups'}>Base Invaders</NavLink>

					<Tooltip content={'Change Theme'} delay={250}>
						<AnimatePresence exitBeforeEnter>
							{theme === 'light' ? (
								<motion.button
									{...themeButtonAnimations}
									onClick={() => setTheme('dark')}
									id={'theme-button'}
									key={'dark'}
								>
									<Icon className='fas fa-moon' color={'white'} fontSize={20} />
								</motion.button>
							) : (
								<motion.button
									{...themeButtonAnimations}
									onClick={() => setTheme('light')}
									id={'theme-button'}
									key={'light'}
								>
									<Icon className='fas fa-sun' color={'white'} fontSize={20} />
								</motion.button>
							)}
						</AnimatePresence>
					</Tooltip>

					<Button
						transparent
						height={'100%'}
						onClick={handleMobileClick}
						marginRight={3}
						marginLeft={2}
						id="hamburger-button"
					>
						<Icon className={'fas fa-bars'} color={'white'} fontSize={21} />
					</Button>

					{user ? (
						<UserWrapper onClick={handleUserClick}>
							<Img src={user.avatar} width={40} height={40} objectFit={'cover'} borderRadius={'50%'} draggable={false} />
							<Icon className={'fas fa-caret-down'} color={'white'}
							style={{ transform: dropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: '0.25s all'}} />
						</UserWrapper>
					) : (
						<Link
							fontFamily={'link'}
							color={'rgba(255, 255, 255, 0.7)'}
							hoverColor={'black'}
							hoverBackgroundColor={'white'}
							fontSize={11}
							textTransform={'uppercase'}
							textDecoration={'none'}
							letterSpacing={2}
							borderWidth={1}
							borderColor={'rgba(255, 255, 255, 0.7)'}
							borderStyle={'solid'}
							px={'7px'}
							py={'10px'}
							href={`${process.env.NEXT_PUBLIC_OAUTH_API_URL}/login?redirect_uri=${process.env.NEXT_PUBLIC_API_URL}/auth/new?return_path=${router.asPath}`}
						>
							Steam Login
						</Link>
					)}
				</NavWrapper>
			</NavContent>
			<MobileNavWrapper open={mobileNavOpen}>
				<NextLink href={'/'} passHref>
					<NavLink>Home</NavLink>
				</NextLink>
				<NextLink href={'/leaderboards'} passHref>
					<NavLink>Leaderboards</NavLink>
				</NextLink>
				<NextLink href={'/tickets'} passHref>
					<NavLink>Support</NavLink>
				</NextLink>
				<NavLink href={'/link'}>Link Your Accounts</NavLink>
				<NavLink href={'https://donate.rustoria.co'}>Donate</NavLink>
				<NextLink href={'/servers'} passHref>
					<NavLink>Servers</NavLink>
				</NextLink>
				<NextLink href={'/rules'} passHref>
					<NavLink>Rules</NavLink>
				</NextLink>
				<NextLink href={'/clans'} passHref>
					<NavLink>Clans</NavLink>
				</NextLink>
				<NavLink href={'https://discord.com/invite/BbfhEhT'}>Discord</NavLink>
				<NavLink href={'https://rustoria.co/signups'}>Base Invaders</NavLink>
			</MobileNavWrapper>
			{dropdownOpen && user && (
				<UserDropdownWrapper>
					<UserInfo>
						<Img src={user.avatar} width={33} height={33} objectFit={'cover'} borderRadius={'50%'} marginRight={2} />
						<div>
							{user.username}
							<P color={'textSecondary'} fontSize={12}>{user.id}</P>
						</div>
					</UserInfo>
					<NextLink href={`/users/${user.id}`} passHref>
						<NavLink>
							<Icon className={'fas fa-user-circle'} fontSize={15} marginRight={2}/>
							Profile
						</NavLink>
					</NextLink>
					<NextLink href={user.team ? `/clans/${user.team?.name}` : '/create-clan'} passHref>
						<NavLink>
							<Icon className={'fas fa-users'} fontSize={15} marginRight={2}/>
							{user.team?.name ? 'Clan' : 'Create Clan'}
							<NewTag>{user.team?.name ? 'New Update' : 'New'}</NewTag>
						</NavLink>
					</NextLink>
					<NextLink href={'/link'} passHref>
						<NavLink>
							<Icon className={'fas fa-link'} fontSize={15} marginRight={2}/>
							Linked Accounts
						</NavLink>
					</NextLink>
					<NavLink onClick={() => logout()}>
						<Icon className={'fas fa-sign-out-alt'} fontSize={15} marginRight={2}/>
						Logout
					</NavLink>
				</UserDropdownWrapper>
			)}
		</Wrapper>
	);
};

export default NavigationBar;
